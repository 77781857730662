<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="activities"
              :show-labels="false"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row v-show="dataTable.visible">
          <b-col>
            <activities-table ref="activities" :mode="filter" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from '@/components/FilteringPanel'

import ActivitiesTable from '@/views/AccountTouchpoints/ActivitiesTable'

export default {
  name: 'Activities',
  props: { filter: { type: String, default: '' } },
  components: {
    FilteringPanel,
    ActivitiesTable
  },
  data: function () {
    return {
      isLoading: false,
      isReviewer: true,
      filteringPanel: {
        loaded: false,
        districts: [],
        selected: {},
        filters: [
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Submitter',
            title: 'Submitter',
            name: 'submitter',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Activitity category',
            title: 'Activitity category',
            name: 'activity_type',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Account category',
            title: 'Account Category',
            name: 'category',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'territory',
            tooltip: 'Territory',
            title: 'Territory',
            name: 'territory',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },

          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Country name',
            title: 'Country',
            name: 'country',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'State name',
            title: 'State',
            name: 'state',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'City name',
            title: 'City',
            name: 'city',
            trackby: 'id',
            label: 'label',
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: 'select',
            dataType: 'string',
            tooltip: 'Activity state',
            title: 'Reviewed',
            name: 'reviewed',
            trackby: 'id',
            label: 'label',
            options: this.$form.yesNo.options,
            selected: {},
            multiple: false
          },
          {
            type: 'daterange',
            dataType: 'datetime',
            tooltip: 'Activity period',
            title: 'Activity date',
            name: 'period'
          }
        ]
      },
      dataTable: {
        visible: true
      }
    }
  },
  computed: {},
  mounted () {},
  methods: {
    async loadDictionaries () {
      const users = async () => {
        let response = await this.$api.get('users')

        this.filteringPanel.filters.find(
          f => f.name === 'submitter'
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }))
      }

      const territories = async () => {
        let response = await this.$api.get('dictionaries/territories')

        this.filteringPanel.filters.find(
          f => f.name === 'territory'
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }))
      }

      const types = async () => {
        let response = await this.$api.get('activities/types')

        this.filteringPanel.filters.find(
          f => f.name === 'activity_type'
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }))
      }

      const categories = async () => {
        let response = await this.$api.get('dictionaries/accounts/categories')

        this.filteringPanel.filters.find(
          f => f.name === 'category'
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }))
      }

      const districts = async () => {
        let response = await this.$api.post('dictionaries/countries/mapped', {
          ignore_restrictions: false
        })

        this.filteringPanel.districts = response

        this.filteringPanel.filters.find(
          f => f.name === 'country'
        ).options = this.$helpers.getDistinctArray(
          response,
          'country',
          'country',
          'id',
          'label'
        )
      }
      Promise.all([
        users(),
        territories(),
        types(),
        categories(),
        districts()
      ]).then(() => (this.filteringPanel.loaded = true))
    },
    onCustomFilterSelected () {
      this.getData()
    },

    onFilteringPanelLoad () {
      this.getData()
    },
    onFilteringPanelStateChange () {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible
    },

    filterData: function (e) {
      //let self = this;

      this.filteringPanel.selected = e
    },
    getData () {
      //let self = this;

      let fp = this.$refs.filteringPanel

      if (!fp.isSearchAllowed()) return

      let f = fp.selected

      let payload = {
        submitters: f.submitter ? f.submitter.map(i => i.id) : [],
        activity_types: f.activity_type
          ? f.activity_type.map(i => i.label)
          : [],
        categories: f.category ? f.category.map(i => i.label) : [],
        territories: f.territory ? f.territory : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        reviewed: f.reviewed,
        period: f.period
      }
      console.log('activities.getData:', payload)

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f['custom-filter'])
        )
      }

      this.$refs['activities'].getData(payload)
    },
    dropdownFilter (_name) {
      return this.filteringPanel.filters.find(n => n.title === _name)
    },
    onInvoicesLoad () {}
  },
  watch: {
    'filteringPanel.selected.country': function (newVal) {
      if (!newVal) return

      this.filteringPanel.filters.find(
        f => f.name === 'state'
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        'state',
        'state',
        'id',
        'label'
      )
    },
    'filteringPanel.selected.state': function (newVal) {
      if (!newVal) return
      this.filteringPanel.filters.find(
        f => f.name === 'city'
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.state)
        ),
        'city',
        'city',
        'id',
        'label'
      )
    }
  }
}
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
